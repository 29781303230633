import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/shared/seo";
const MainWrapper = styled.div`
  width: 80%;
  margin: auto;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .gatsby-image-wrapper {
    max-width: 480px;
    margin: auto;
  }
  div,
  p {
    text-align: center;
    line-height: 24px;
  }
  p {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin: 32px 0;
`;
function uninstall() {
  return (
    <>
      <Seo title="How to Uninstall PDF Reader" special={true} />
      <MainWrapper>
        <Title>Uninstall instructions</Title>
        <StaticImage
          src="../images/uninstall/en_1.png"
          quality={100}
          loading="eager"
          alt="Step 1"
        />
        <p>
          <div>
            Open the Windows Control Panel and find add/remove programs.
          </div>
          <div>
            Wait for the control panel to populate. Select PDF Reader 10, and
            press uninstall.
          </div>
        </p>
        <StaticImage
          src="../images/uninstall/en_2.png"
          quality={100}
          loading="eager"
          alt="Step 2"
        />
        <p>
          <div>
            Click ‘Yes’ when asked if you would like to remove PDF Reader 10
            from your computer.
          </div>
        </p>
        <StaticImage
          src="../images/uninstall/en_3.png"
          quality={100}
          loading="eager"
          alt="Step 3"
        />
        <p>
          <div>PDF Reader 10 is now being uninstalled.</div>
          <div>Please wait while for the uninstall process to complete.</div>
        </p>
        <StaticImage
          src="../images/uninstall/en_4.png"
          quality={100}
          loading="eager"
          alt="Step 4"
        />
        <p>
          <div>
            You need to restart your computer to finalize the uninstall process.
          </div>
          <div>
            Once you restart your computer, PDF Reader 10 will have been
            completely removed from your computer.
          </div>
        </p>
      </MainWrapper>
    </>
  );
}

export default uninstall;
